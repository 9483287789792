<button
  lf-secondary-button
  lf-icon-button
  #showInsightsButton
  [ngClass]="{ active: activeInsightsButton() }"
  (click)="showInsights()">
  <i class="lf-icon lf-icon-bell"></i>
</button>

@if (counter()) {
  <span class="counter">
    {{ counter() }}
  </span>
}
