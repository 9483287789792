import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  transform<T>(value: T[], query: string, searchBy: keyof T, searchBy2?: keyof T[typeof searchBy]): T[] {
    if (searchBy2)
      return value.filter((v) => v[searchBy]?.[searchBy2]?.toString().toLowerCase().includes(query.toLowerCase()));
    else return value.filter((v) => v[searchBy]?.toString().toLowerCase().includes(query.toLowerCase()));
  }
}
