<div class="page-tabs-container">
  <div
    class="tabs"
    #tabsContainer>
    @for (tab of tabs().visible; track tab.name) {
      <app-tab
        #tab
        (hideCategory)="removeCategoryFromVisibleTabs(tab)"
        [name]="tab.name"
        [data]="tab.data" />
    }
  </div>
  <div class="add-tab">
    <app-add-tab
      #tab
      [abmMenuTriggerFor]="menu" />
  </div>
</div>

<ng-content select="[btn]"></ng-content>

<abm-menu #menu="menu">
  @if (tabs().free.length > 7) {
    <abm-search-field
      [withLabel]="false"
      [(ngModel)]="query"
      [placeholder]="'placeholder.search' | t"
      (click)="$event.stopImmediatePropagation()" />
  }
  <div class="menu">
    @for (item of tabs().free | search: query() : 'name'; track item.name) {
      <abm-menu-item (click)="addCategoryToObservedTabs(item)">{{ item.name }}</abm-menu-item>
    }
  </div>
</abm-menu>
