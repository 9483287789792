import { SearchFieldComponent } from '@abm/controls';
import { MenuModule } from '@abm/menu';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  computed,
  inject,
  output,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@lf/translate-core';
import { CategoryPageTab, PageTab } from '@modules/tabs/interfaces/tab.interface';
import { CategoryService } from '@services/category/category.service';
import { DBService, NotFoundRecordError } from '@services/d-b/d-b.service';
import { CATEGORY } from '@tokens/category.token';
import { CategoryId, CategoryType } from '@type/categories.type';
import { sortByString } from '@utils/sort-array';
import { animationFrameScheduler, fromEvent, tap, throttleTime } from 'rxjs';
import { SearchPipe } from 'src/app/core/pipe/search/search.pipe';
import { translateTabListByIndex } from '../../utils/translate-tab-list.util';
import { AddTabComponent } from '../add-tab/add-tab.component';
import { BaseTabComponent } from '../base-tab/base-tab.component';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-page-tabs',
  imports: [MenuModule, TabComponent, AddTabComponent, SearchFieldComponent, TranslatePipe, FormsModule, SearchPipe],
  standalone: true,
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTabsComponent implements AfterViewInit {
  #visibleTabIds = signal<number[]>([]);

  #db = inject(DBService);

  #categoryService = inject(CategoryService);

  #element = inject(ElementRef).nativeElement;

  #destroyRef = inject(DestroyRef);

  #sortedCategoryList = computed(() => this.#sortCategories(this.#categoryService.categoryList()));

  tabList = viewChildren<BaseTabComponent>('tab');

  tabsContainer = viewChild<ElementRef<HTMLElement>>('tabsContainer');

  roundCorners = output<boolean>();

  category = inject(CATEGORY).value;

  tabs = computed(() => this.#divideTabs());

  query = signal('');

  constructor() {
    this.#setVisibleTabs();
  }

  ngAfterViewInit(): void {
    translateTabListByIndex(
      this.tabList()
        ?.filter((t) => t.isPinned)
        .map((e) => e.element),
    );
    translateTabListByIndex(
      this.tabList()
        ?.filter((t) => !t.isPinned)
        .map((e) => e.element),
    );
    this.mouseMoveHandler();
  }

  @HostListener('mouseleave')
  mouseleave() {
    this.roundCorners.emit(!this.tabList()?.at(0)?.active);
  }

  @HostListener('wheel', ['$event'])
  wheel(event: WheelEvent) {
    event.preventDefault();
    const el = this.tabsContainer()?.nativeElement;

    if (el) {
      el.scrollLeft += 120 * (event.deltaY / Math.abs(event.deltaY));
    }
  }

  async addCategoryToObservedTabs(item: PageTab<CategoryType>) {
    await this.updateVisibleTabsInDB(item.data.id, 'add');
    await this.#setVisibleTabs();
    this.#scrollToActiveTab();
  }

  async removeCategoryFromVisibleTabs(item: PageTab<CategoryType>) {
    await this.updateVisibleTabsInDB(item.data.id, 'remove');
    await this.#setVisibleTabs();
    this.#scrollToActiveTab();
  }

  private async updateVisibleTabsInDB(id: CategoryId, action: 'add' | 'remove'): Promise<number[]> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.#db.hasConnection()) {
          let data: CategoryId[] = await this.#getFreeCateoriesFromDB();
          if (action === 'add') {
            data.push(id);
          } else {
            data = data.filter((categoryId) => categoryId !== id);
          }
          data = await this.#db.update('category', 'visible_category_tabs', data);
          resolve(data);
        } else {
          this.#db.getDB('category');
          this.updateVisibleTabsInDB(id, action);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  private initRoundCorners() {
    this.roundCorners.emit(!this.tabList()?.at(0)?.active);
  }

  private mouseMoveHandler() {
    fromEvent<Event>(this.#element, 'mousemove')
      .pipe(
        throttleTime(50, animationFrameScheduler),
        tap((event: Event) => {
          if (this.tabList()?.at(0)?.active) {
            this.roundCorners.emit(false);
            return;
          }

          if (
            !this.tabList()
              ?.at(0)
              ?.element.contains(event.target as Node | null)
          ) {
            this.roundCorners.emit(true);
            return;
          }

          this.roundCorners.emit(!!this.tabList()?.at(0)?.active);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #scrollToActiveTab() {
    const tablistArr = Array.from(this.tabList());
    const el = tablistArr.find((e) => e.active)?.element;

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

    this.initRoundCorners();
  }

  async #getFreeCateoriesFromDB<T = any>(): Promise<T> {
    return await this.#db.getByKey<CategoryId[]>('category', 'visible_category_tabs').catch((e) => {
      if (e instanceof NotFoundRecordError) {
        return this.#db.update('category', 'visible_category_tabs', []).catch((e) => console.error(e));
      }

      return e;
    });
  }

  #sortCategories(categories: CategoryType[]): CategoryType[] {
    return categories.sort((a, b) => {
      const d1 = a['name'];
      const d2 = b['name'];

      return sortByString(String(d1).toLowerCase(), String(d2).toLowerCase(), 'asc');
    });
  }

  async #setVisibleTabs() {
    const idList = await this.#getFreeCateoriesFromDB().catch((e) => {
      this.#visibleTabIds.set([]);
      console.error(e);
    });

    this.#visibleTabIds.set(idList);
    return idList;
  }

  #divideTabs() {
    const result = {
      visible: [] as CategoryPageTab[],
      free: [] as CategoryPageTab[],
    };
    const categories = this.#sortedCategoryList();
    const visibleTabIds = this.#visibleTabIds();

    for (const category of categories) {
      visibleTabIds.includes(category.id) ?
        result.visible.push(CategoryPageTab.create(category))
      : result.free.push(CategoryPageTab.create(category));
    }

    return result;
  }
}
